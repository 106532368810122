import {inject} from 'vue';
import axios from 'axios';
const $get=inject("$get");
export const uploadUrl=()=>{
	// return "http://localhost/NToy/public/index.php/Upload/UploadImg";
	return "https://ntoy.lskyo.com/api/public/index.php/Upload/UploadImg";
}
export const randomString=(len)=>{
	len = len || 16;
	var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
	var maxPos = $chars.length;
	var pwd = '';
	for (let i = 0; i < len; i++) {
		pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
	}
	return pwd;
}
